.tag.malibu {
  background-color: #4fc3f7;
}
.tag.sky_blue {
  background-color: #03a9f4;
}
.tag.blue {
  background-color: #304ffe;
}
.tag.orange {
  background-color: #ff9800;
}
.tag.yellow {
  background-color: #ffca28;
}
.tag.tomato {
  background-color: #e53935;
}
.tag.royal {
  background-color: #880e4f;
}
.tag.bilbao {
  background-color: #558b2f;
}
.tag.cornflower {
  background-color: #8c9eff;
}
.tag.light_sky {
  background-color: #90caf9;
}
.tag.indigo {
  background-color: #6a1b9a;
}
.tag.dark_indigo {
  background-color: #4a148c;
}
.tag.scooter {
  background-color: #00838f;
}
.tag.atoll {
  background-color: #006064;
}
.tag.gorse {
  background-color: #ffea00;
}
.tag.bean {
  background-color: #3e2723;
}
.tag.hemp {
  background-color: #a1887f;
}
.courses .course:hover {
  padding-bottom: 10px;
}
.courses .course:hover .course-i {
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2);
}
.courses .course:hover .course-i .button-w {
  display: block;
}
.courses .course {
  padding-bottom: 43px;
}
.courses .course .course-i {
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 10px;
  margin: 0 5px 5px;
}
.courses .course .course-i .tags .tag.black-text {
  color: #292929;
}
.courses .course .course-i .tags .tag {
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  padding: 5px 12px;
}
.courses .course .course-i .img-w .img-tags {
  position: relative;
}
.courses .course .course-i .img-w .img-tags .tag.promo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 50px;
  padding: 0;
  left: auto;
  right: 0;
  font-size: 16px;
}
.courses .course .course-i .img-w .img-tags .tag {
  position: absolute;
  top: 0;
  left: 0;
}
.courses .course .course-i .img-w .img-tags .tag:nth-child(2) {
  top: 30px;
}
.courses .course .course-i .img-w .img-tags img {
  width: 100%;
  height: auto;
}
.courses .course .course-i .tags-title {
  height: 115px;
}
.courses .course .course-i .tags-title .bottom-tags {
  text-align: center;
}
.courses .course .course-i .tags-title .bottom-tags .tag {
  margin-top: 5px;
}
.courses .course .course-i .tags-title .title {
  display: block;
  margin-top: 5px;
  text-align: center;
  font-size: 11.9px;
  color: #292929;
}
.courses .course .course-i .price-w {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 70px;
}
.courses .course .course-i .price-w .price {
  color: #3799db;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}
.courses .course .course-i .price-w .price del {
  color: #b3b3b3;
  font-weight: normal;
  font-size: 12px;
}
.courses .course .course-i .footer-card {
  text-align: center;
  font-size: 11.9px;
  margin-bottom: 15px;
}
.courses .course .course-i .button-w .btn {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 1020px) {
  .courses .course .course-i .button-w {
    display: none;
  }
}
