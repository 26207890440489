@import "../global/___variables"

tag_colors = 'malibu' #4FC3F7,
             'sky_blue' #03A9F4,
             'blue' #304FFE,
             'orange' #FF9800,
             'yellow' #FFCA28,
             'tomato' #E53935,
             'royal' #880E4F,
             'bilbao' #558B2F,
             'cornflower' #8C9EFF,
             'light_sky' #90CAF9,
             'indigo' #6A1B9A,
             'dark_indigo' #4A148C,
             'scooter' #00838F,
             'atoll' #006064,
             'gorse' #FFEA00,
             'bean' #3E2723,
             'hemp' #A1887F

for tag_color in tag_colors
    .tag.{tag_color[0]}
        background-color: tag_color[1]

.courses
    .course:hover
        padding-bottom 10px
        .course-i
            box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.2)
            .button-w
                display block
    .course
        padding-bottom 43px
        .course-i
            border 1px solid #e1e1e1 + 30%
            background: white
            padding 10px
            margin 0 5px 5px
            .tags
                .tag.black-text
                    color texts
                .tag
                    text-transform uppercase
                    display inline-block
                    color white
                    font-size 11px
                    font-weight bold
                    padding 5px 12px
            .img-w
                .img-tags
                    position relative
                    .tag.promo
                        display flex
                        align-items: center
                        justify-content center
                        width 54px
                        height 50px
                        padding 0
                        left auto
                        right 0
                        font-size 16px
                    .tag
                        position absolute
                        top 0
                        left 0
                        &:nth-child(2)
                            top 30px
                    img
                        width 100%
                        height auto
            .tags-title
                height 115px
                .bottom-tags
                    text-align center
                    .tag
                        margin-top 5px
                .title
                    display block
                    margin-top 5px
                    text-align center
                    font-size 11.9px
                    color texts
            .price-w
                display flex
                flex-direction: column
                justify-content flex-end
                height 70px
                .price
                    color #3799DB
                    text-align center
                    font-size 20px
                    font-weight bold
                    margin-bottom 0
                .price del
                    color texts2 + 50%
                    font-weight normal
                    font-size 12px
            .footer-card
                text-align center
                font-size 11.9px
                margin-bottom 15px
            .button-w
                .btn
                    padding-left 0
                    padding-right: 0
                +above(1020px)
                    display none
